import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import de from 'date-fns/locale/de'; // Import the German locale
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";
import en from "date-fns/locale/en-GB";

import Header from "./components/Header";
import Loading from "./components/Loading";
import "../css/style.css"

import agbFile from "../files/Verzichtserklärung2024.pdf";
import { api, getAddressOfOrder, getCountries } from "ticketino-api-client";

const Home = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [orderId, setOrderId] = useState(sessionStorage.getItem("OrderId"));
    const [eventId, setEventId] = useState(0);
    const [posId, setPosId] = useState(0);

    const [token, setToken] = useState("");

    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasNoDrivingLicense, setHasNoDrivingLicense] = useState(false);
    const [isAgbChecked, setIsAgbChecked] = useState(false);

    const noDrivingLicenseTicketTypeId = "782507";

    const [ticketAddress, setTicketAddress] = useState({
        salutation: "",
        firstname: "",
        name: "",
        street: "",
        country: "",
        countryId: 176,
        postCode: "",
        city: "",
        mobile: "",
        email: "",
        confirmemail: "",
        ticketTypeId: "",
        customValue1: "",
        customValue2: "",
        customValue3: null,
        customValue4: null,
        birthDate: new Date()
    })

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        registerLocale('de', de);
        registerLocale("en", en);
        registerLocale("it", it);
        registerLocale("fr", fr);
        setDefaultLocale('de');

        loadToken();
    }, []);

    useEffect(() => {
        requestResources();

        if (token != "") {
            loadCountries();
        }
    }, [language, token]);

    const requestResources = async () => {
        await axios.get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
            sessionStorage.setItem("token", res.data);
            setToken(res.data);

            requestFormSettings();

            if (orderId > 0) {
                loadAddress(orderId);
            }
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setEventId(res.data.eventId);
            setPosId(res.data.posId);
        });
    };

    const deleteTicketsFromBasket = async (ticketIds) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: { "TicketsToRemove": ticketIds } });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const loadAddress = async (orderId) => {
        try {
            const order = await getShopBasketOrder(orderId);

            if (order.id > 0) {

                let ticket = order?.tickets[0];

                if (ticket) {
                    // const dateString = ticket.birthDate ?? "";
                    // const [day, month, year] = dateString.split('.').map(Number);
                    // const date = new Date(year, month - 1, day);

                    ticket.birthDate = new Date(ticket.birthDate);

                    setTicketAddress({
                        ...ticketAddress, ...ticket,
                        name: ticket.lastname,
                        customValue1: ticket.customValue1,
                        customValue2: ticket.customValue2,
                        customValue3: ticket.customValue3,
                        customValue4: ticket.customValue4,
                    })
                    setIsSubmitted(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const loadCountries = async () => {
        let updatedCountries = await getCountries(language);

        const indexCh = updatedCountries.findIndex(c => c.id == 176);
        const indexDe = updatedCountries.findIndex(c => c.id == 229);
        const indexAu = updatedCountries.findIndex(c => c.id == 228);

        const ch = updatedCountries[indexCh];
        const de = updatedCountries[indexDe];
        const au = updatedCountries[indexAu];

        updatedCountries.splice(indexCh, 1);
        updatedCountries.splice(indexDe, 1);
        updatedCountries.splice(indexAu, 1);

        updatedCountries.unshift(au);
        updatedCountries.unshift(de);
        updatedCountries.unshift(ch);

        setCountries(updatedCountries);
    }

    const getShopBasketOrder = async (orderId) => {
        try {
            const response = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const startOrder = async (posId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const response = await axios.post(`${baseUrl}/ShopBasket/Order`, order);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
        }
    };

    const onSubmit = async () => {

        if (isSubmitted) {
            await addAddressToBasket(orderId);
            const tickets = await addTicketToBasket(orderId);

            if (tickets.length > 1) {
                await deleteTicketsFromBasket([tickets[0]?.id]);
                await addAddressToTicket(tickets[1]?.id);
                sessionStorage.setItem("OrderId", orderId)
                navigate(`/${language}/checkout`);
            } else if (tickets.length == 1) {
                await addAddressToTicket(tickets[0]?.id);
                sessionStorage.setItem("OrderId", orderId)
                navigate(`/${language}/checkout`);
            }
        } else {
            const order = await startOrder(posId);

            if (order.id > 0) {
                await addAddressToBasket(order.id);
                const tickets = await addTicketToBasket(order.id);

                if (tickets.length > 0) {
                    await addAddressToTicket(tickets[0]?.id);
                    sessionStorage.setItem("OrderId", order.id)
                    navigate(`/${language}/checkout`);
                }
            }
        }
    };

    const addTicketToBasket = async (orderId) => {
        let addTicketTypes = [];

        addTicketTypes = [{
            ticketTypeId: ticketAddress.ticketTypeId,
            quantity: 1,
        }];

        try {
            const response = await axios.post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { ticketsToAdd: addTicketTypes });
            return response.data.tickets;
        } catch (error) {
            console.error(error);
        }
    };

    const formatDate = (myDate) => {
        const year = myDate.getFullYear();
        const month = String(myDate.getMonth() + 1).padStart(2, '0');
        const day = String(myDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const addAddressToTicket = async (ticketId) => {
        let addAddressToTicketBody = { ...ticketAddress };
        addAddressToTicketBody.birthDate = formatDate(ticketAddress.birthDate);

        try {
            const response = await axios.put(`${baseUrl}/Ticket/${ticketId}/Address`, ticketAddress);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const addAddressToBasket = async (orderId) => {
        let addAddressToBasketBody = { ...ticketAddress };
        addAddressToBasketBody.firstName = ticketAddress.firstname;
        addAddressToBasketBody.name = ticketAddress.name;
        addAddressToBasketBody.telephone = ticketAddress.mobile;
        addAddressToBasketBody.code1 = ticketAddress.customValue1;
        addAddressToBasketBody.code2 = ticketAddress.customValue2;
        addAddressToBasketBody.code3 = formatDate(ticketAddress.birthDate);
        addAddressToBasketBody.code4 = ticketAddress.customValue3;

        try {
            const response = await axios.put(`${baseUrl}/Order/${orderId}/Address`, addAddressToBasketBody);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    // validating input fields
    let validation =
        /^.{1,70}$/.test(ticketAddress.firstname) &&
        /^.{1,70}$/.test(ticketAddress.name) &&
        /^.{1,70}$/.test(ticketAddress.street) &&
        /^.{1,70}$/.test(ticketAddress.postCode) &&
        /^.{1,70}$/.test(ticketAddress.city) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(ticketAddress.countryId) &&
        /^.{1,70}$/.test(ticketAddress.ticketTypeId) &&
        /^.{1,70}$/.test(ticketAddress.mobile) &&
        /^\S+@\S+\.\S+$/.test(ticketAddress.email);

    if (!hasNoDrivingLicense) {
        if (!/^.{1,70}$/.test(ticketAddress.code2)) validation = false
    }

    if (!isAgbChecked) validation = false;

    if (ticketAddress.customValue3 == null && ticketAddress.customValue4 == null) validation = false;

    const onInputChange = (e) => {
        setTicketAddress({ ...ticketAddress, [e.target.name]: e.target.value });
    };

    return (
        <div>
            {loading ? (
                <Loading
                    alignment="center"
                    color="#d3d3d3"
                    bgColor="#fff"
                    position="fixed"
                    top="50%"
                    left="50%"
                />
            ) : (
                <>
                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Header language={language} />
                            </div>
                        </div>
                    </div>

                    {resources.translation && (
                        <div className="container wrapper">
                            <div className="m-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <p>{resources.translation.HomeDescription1}</p>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <p>{resources.translation.HomeDescription2}</p>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h4 className="pink">Anmeldung</h4>
                                    </div>
                                </div>

                                {/* user data */}

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Anrede</span>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="d-inline-block">
                                            <input
                                                id="mr"
                                                type="radio"
                                                name="salutation"
                                                checked={ticketAddress.salutation == resources.translation._Mr}
                                                onChange={(e) => setTicketAddress({ ...ticketAddress, salutation: resources.translation._Mr })}
                                            />
                                            <label className="ms-2 cursor-pointer" htmlFor="mr">Herr</label>
                                        </div>
                                        <div className="d-inline-block ms-4">
                                            <input
                                                id="mrs"
                                                type="radio"
                                                name="salutation"
                                                checked={ticketAddress.salutation == resources.translation._Mrs}
                                                onChange={(e) => setTicketAddress({ ...ticketAddress, salutation: resources.translation._Mrs })}
                                            />
                                            <label className="ms-2 cursor-pointer" htmlFor="mrs">Frau</label>
                                        </div>
                                        <div className="d-inline-block ms-4">
                                            <input
                                                id="divers"
                                                type="radio"
                                                name="salutation"
                                                checked={ticketAddress.salutation == resources.translation._Divers}
                                                onChange={(e) => setTicketAddress({ ...ticketAddress, salutation: resources.translation._Divers })}
                                            />
                                            <label className="ms-2 cursor-pointer" htmlFor="divers">Divers</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Vorname *</span>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            name="firstname"
                                            className="form-control custom-textbox"
                                            value={ticketAddress.firstname}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Nachname *</span>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control custom-textbox"
                                            value={ticketAddress.name}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Strasse *</span>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            name="street"
                                            className="form-control custom-textbox"
                                            value={ticketAddress.street}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Land *</span>
                                    </div>
                                    <div className="col-md-9">
                                        <select
                                            className="form-control custom-textbox"
                                            aria-label="Land"
                                            name="country"
                                            value={ticketAddress.countryId}
                                            onChange={(e) => {
                                                setTicketAddress({ ...ticketAddress, countryId: e.target.value, country: countries?.find(c => c.id == e.target.value)?.name })
                                            }}>
                                            {countries.map((c, index) => (
                                                <option value={c.id} key={index}>
                                                    {c.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>PLZ * / Stadt *</span>
                                    </div>
                                    <div className="col-5 col-md-3">
                                        <input
                                            type="text"
                                            name="postCode"
                                            className="form-control custom-textbox"
                                            value={ticketAddress.postCode}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                    <div className="col-7 col-md-6">
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control custom-textbox"
                                            value={ticketAddress.city}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                {ticketAddress.countryId == 176 &&
                                    <div className="row mt-4">
                                        <div className="col-md-3">
                                            <span>Kanton *</span>
                                        </div>
                                        <div className="col-md-9">
                                            <select
                                                className="form-control custom-textbox"
                                                aria-label="customValue1"
                                                name="customValue1"
                                                value={ticketAddress.customValue1}
                                                onChange={(e) => onInputChange(e)}>
                                                <option value="">
                                                    {resources.translation._PleaseChoose}
                                                </option>
                                                <option>Aargau</option>
                                                <option>Appenzell Ausserrhoden</option>
                                                <option>Appenzell Innerrhoden</option>
                                                <option>Basel Land</option>
                                                <option>Basel Stadt</option>
                                                <option>Bern</option>
                                                <option>Freiburg</option>
                                                <option>Genève</option>
                                                <option>Glarus</option>
                                                <option>Graubünden</option>
                                                <option>Jura</option>
                                                <option>Luzern</option>
                                                <option>Neuchâtel</option>
                                                <option>Nidwalden</option>
                                                <option>Obwalden</option>
                                                <option>Sankt Gallen</option>
                                                <option>Schaffhausen</option>
                                                <option>Schwyz</option>
                                                <option>Solothurn</option>
                                                <option>Thurgau</option>
                                                <option>Ticino</option>
                                                <option>Uri</option>
                                                <option>Valais</option>
                                                <option>Vaud</option>
                                                <option>Zug</option>
                                                <option>Zürich</option>
                                            </select>
                                        </div>
                                    </div>
                                }

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Führerausweis *</span>
                                    </div>
                                    <div className="col-md-9">
                                        <select
                                            className="form-control custom-textbox"
                                            aria-label="ticketTypeId"
                                            name="ticketTypeId"
                                            value={ticketAddress.ticketTypeId}
                                            onChange={(e) => {
                                                if (e.target.value === noDrivingLicenseTicketTypeId) {
                                                    setHasNoDrivingLicense(true);
                                                } else {
                                                    setHasNoDrivingLicense(false);
                                                }

                                                setTicketAddress({ ...ticketAddress, ticketTypeId: e.target.value });
                                            }}>
                                            <option value="">
                                                {resources.translation._PleaseChoose}
                                            </option>
                                            <option value="782507">Kein Führerausweis</option>
                                            <option value="782504">A1 (bis 125cm3) Lernfahrer</option>
                                            <option value="782503">A1 (bis 125cm3)</option>
                                            <option value="782506">A beschränkt (unter 35kW) Lernfahrer</option>
                                            <option value="782505">A beschränkt (unter 35kW)</option>
                                            <option value="782502">A unbeschränkt Lernfahrer</option>
                                            <option value="782501">A unbeschränkt</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>E-Mail *</span>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control custom-textbox"
                                            value={ticketAddress.email}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Geburtsdatum *</span>
                                    </div>
                                    <div className="col-md-9">
                                        <DatePicker
                                            name="birthday"
                                            selected={ticketAddress.birthDate}
                                            dateFormat="dd.MM.yyyy"
                                            locale={language}
                                            maxDate={new Date()}
                                            className="form-control custom-textbox"
                                            onChange={(date) => setTicketAddress({ ...ticketAddress, birthDate: date })}
                                            showYearDropdown
                                            scrollableYearDropdown={true}
                                            yearDropdownItemNumber={200}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Führerausweis Nr. </span>
                                        {!hasNoDrivingLicense && <span>*</span>}
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            name="customValue2"
                                            className="form-control custom-textbox"
                                            value={ticketAddress.customValue2}
                                            onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Telefon *</span>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            name="mobile"
                                            className="form-control custom-textbox"
                                            value={ticketAddress.mobile}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <span>Kaufen Sie dieses Jahr ein Motorrad?</span>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="d-inline-block">
                                            <input
                                                id="buyMotoYes"
                                                type="radio"
                                                name="customValue3"
                                                checked={ticketAddress.customValue3 == true}
                                                onChange={() => setTicketAddress({ ...ticketAddress, customValue3: true, customValue4: null })}
                                            />
                                            <label className="ms-2 cursor-pointer" htmlFor="buyMotoYes">Ja</label>
                                        </div>
                                        <div className="d-inline-block ms-4">
                                            <input
                                                id="buyMotoNo"
                                                type="radio"
                                                name="customValue3"
                                                checked={ticketAddress.customValue3 == false}
                                                onChange={() => setTicketAddress({ ...ticketAddress, customValue3: false, customValue4: null })}
                                            />
                                            <label className="ms-2 cursor-pointer" htmlFor="buyMotoNo">Nein</label>
                                        </div>
                                        <div className="d-inline-block ms-4">
                                            <input
                                                id="buyMotoMaybe"
                                                type="radio"
                                                name="customValue4"
                                                checked={ticketAddress.customValue4 == true}
                                                onChange={() => setTicketAddress({ ...ticketAddress, customValue4: true, customValue3: null })}
                                            />
                                            <label className="ms-2 cursor-pointer" htmlFor="buyMotoMaybe">Vielleicht</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="checkboxAgb"
                                        onChange={() => setIsAgbChecked(!isAgbChecked)} />
                                    <label className="form-check-label" htmlFor="checkboxAgb">
                                        Ich bestätige die Richtigkeit der Angaben und erkläre hiermit mein persönliches Einverständnis mit der <a href={agbFile} target="_blank">Verzichtserklärung</a>.
                                    </label>
                                </div>

                                {/* button confirm */}

                                <div className="row mt-5">
                                    <div className="offset-md-8 col-md-4 text-end">
                                        <button
                                            className="custom-button"
                                            onClick={onSubmit}
                                            disabled={!validation}
                                        >
                                            {resources.translation.HomeNext}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-5 text-center">
                                {resources.translation && (
                                    <small style={{ color: '#ffa834' }}>powered by <a style={{ color: '#ffa834', textDecoration: 'none' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                                )}
                            </p>
                        </div>
                    )}
                </>
            )
            }
        </div >
    );
};

export default Home;
